import { defineNuxtRouteMiddleware, navigateTo } from "#imports";
import { ElMessage } from "element-plus";

export default defineNuxtRouteMiddleware(async (to, from) => {
  console.log(to,from);
  if(!(to.params as any).id){
    ElMessage.error("code does not exist");
    return navigateTo('/people');
  }
});
